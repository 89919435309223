import React, { useEffect, useState } from "react";

export const usePreferredColorScheme = () => {
  const [theme, setTheme] = useState("light"); // Default theme

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => setTheme(e.matches ? "dark" : "light");

    // Initial check
    handleChange(mediaQuery);

    // Listen for changes
    mediaQuery.addListener(handleChange);

    // Cleanup listener on component unmount
    return () => mediaQuery.removeListener(handleChange);
  }, []);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", theme === "dark");
  }, [theme]);

  return theme;
};
