function formatNumber(num) {
    if (num >= 1000 && num < 1000000) {
      return (num / 1000).toFixed(1).replace(".", ",") + " тыс.";
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(".", ",") + " млн";
    } else {
      return num.toString();
    }
  }

export default formatNumber