import React from 'react';
import '../css/NotFound.css'; // Make sure to import the CSS file

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="content">
        <h1 className="title">404 Not Found</h1>
        <p className="subtitle">Страница не найдена</p>
      </div>
    </div>
  );
}

export default NotFound;