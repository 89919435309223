// Assuming axiosInstance and getRandomVideo are properly set up
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRandomVideo } from "../api/video";

function RedirectToVideo() {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const data = await getRandomVideo();
        navigate(`/video/${data.id}`);
      } catch (error) {
        console.error("Failed to fetch random video:", error);
      }
    };

    fetchVideo();
  }, [navigate]);

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
}

export default RedirectToVideo;
