import axiosInstance from "./axios";

export const fetchCSRFToken = async () => {
  try {
    const response = await axiosInstance.get("/csrf/");
    return response.data;
  } catch (error) {
    throw error;
  }
};
