import axiosInstance from "./axios";

export const getRandomVideo = async () => {
  try {
    const response = await axiosInstance.get("/videos/get_random_video/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVideoAndABTest = async (videoId, answerData) => {
  try {
    const response = await axiosInstance.post(
      "/videos/ab/" + videoId,
      answerData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const likeVideo = async (videoId, csrfToken) => {
  try {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    const response = await axiosInstance.post(
      "/videos/likes/" + videoId,
      {},
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeVideoLike = async (videoId, csrfToken) => {
  try {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    const response = await axiosInstance.delete("/videos/likes/" + videoId, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const watchVideo = async (videoId, csrfToken) => {
  try {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    const response = await axiosInstance.post("/videos/views/" + videoId, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchComments = async (videoId) => {
  try {
    const response = await axiosInstance.get("/videos/comments/" + videoId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createComment = async (videoId, csrfToken, name, content) => {
  try {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    const response = await axiosInstance.post(
      "/videos/comments/",
      {
        videoId: videoId,
        name: name,
        comment: content,
      },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const trackEvent = async (
  videoId,
  csrfToken,
  start,
  stop,
  end = false
) => {
  try {
    const headers = {
      "X-CSRF-Token": csrfToken,
    };
    const response = await axiosInstance.post(
      "/videos/events/",
      {
        videoId,
        start,
        stop,
        end,
      },
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
