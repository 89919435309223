import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import "../css/VideoPlatform.css";
import formatNumber from "../utils/formatNumber";
import {
  fetchVideoAndABTest,
  likeVideo,
  removeVideoLike,
  watchVideo,
  trackEvent,
} from "../api/video";
import { fetchCSRFToken } from "../api/csrf";
import config from "../config";
import { useNavigate, useParams } from "react-router-dom";
import { usePreferredColorScheme } from "../hooks/usePreferredColorScheme";

function VideoPlatform() {
  const [isInitialPage, setIsInitialPage] = useState(true);
  const [videoA, setVideoA] = useState(null);
  const [videoB, setVideoB] = useState(null);
  const [testId, setTestId] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [CSRFToken, setCSRFToken] = useState("");
  const [startTime, setStartTime] = useState(0);
  const [playing, setPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);
  const [light, setLight] = useState(false);
  const [videoAPreviewId, setVideoAPreviewId] = useState(null);
  const [videoBPreviewId, setVideoBPreviewId] = useState(null);

  const theme = usePreferredColorScheme();

  const playerRef = useRef(null);
  const currentTimeRef = useRef(0);
  const [lastKnownTime, setLastKnownTime] = useState(0);

  const [likeActive, setLikeActive] = useState(false);
  const [dislikeActive, setDislikeActive] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const [previousABVideoIds, setPreviousABVideoIds] = useState({
    ABId: null,
    videoAId: null,
    videoAPreviewId: null,
    videoBId: null,
    videoBPreviewId: null,
    videoWinnerId: null,
    videoPreviewWinnerId: null,
  });
  const [chosenVideoId, setChosenVideoId] = useState(null);

  const { videoId } = useParams();

  const navigation = useNavigate();

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const handlePickA = async () => {
    handlePick(videoA.id, videoAPreviewId);
  };

  const handlePickB = async () => {
    handlePick(videoB.id, videoBPreviewId);
  };

  const handlePick = async (videoId, previewId) => {
    setPreviousABVideoIds({
      ABId: testId,
      videoAId: videoA.id,
      videoAPreviewId: videoAPreviewId,
      videoBId: videoB.id,
      videoBPreviewId: videoBPreviewId,
      videoPreviewWinnerId: previewId,
    });
    setChosenVideoId(videoId);
    navigation("/video/" + videoId);
  };

  const toggleLike = () => {
    // If like is already active, deactivate it. Otherwise, activate like and deactivate dislike.

    setLikeActive(!likeActive);
    if (!likeActive) {
      currentVideo.likeCount++;
      likeVideo(currentVideo.id, CSRFToken);
    } else {
      currentVideo.likeCount--;
      removeVideoLike(currentVideo.id, CSRFToken);
    }
    if (dislikeActive) {
      setDislikeActive(false);
    }
  };

  const toggleDislike = () => {
    // If dislike is already active, deactivate it. Otherwise, activate dislike and deactivate like.
    setDislikeActive(!dislikeActive);
    if (likeActive) {
      currentVideo.likeCount--;
      removeVideoLike(currentVideo.id, CSRFToken);
      setLikeActive(false);
    }
  };

  const handleVideoEnd = async () => {
    const endTime = Math.round(getCurrentTime());
    await trackEvent(videoId, CSRFToken, startTime, endTime, true);
    setStartTime(endTime);
  };

  const handleStop = async () => {
    const fromSeekTime = Math.round(lastKnownTime);
    const currentTime = Math.round(getCurrentTime());

    // Check if stop is not initiated by seek
    if (Math.abs(fromSeekTime - currentTime) < 2) {
      if (currentTime - startTime >= 1) {
        await trackEvent(videoId, CSRFToken, startTime, currentTime);
        setStartTime(currentTime);
      }
    }
  };

  const handleSeek = async (toTime) => {
    const fromSeekTime = Math.round(lastKnownTime);
    const currentTime = Math.round(getCurrentTime());

    if (
      fromSeekTime - startTime > 1 &&
      Math.abs(fromSeekTime - currentTime) > 2
    ) {
      await trackEvent(videoId, CSRFToken, startTime, fromSeekTime);
    }

    setStartTime(Math.round(toTime));
  };

  function getPreviewUrl(video) {
    if (video?.previews?.length > 0) {
      return config.remoteApiUrl + "/videos/previews/" + video.previews[0].id;
    }
    return "";
  }

  const handleProgress = ({ playedSeconds }) => {
    currentTimeRef.current = playedSeconds;
    setLastKnownTime(playedSeconds);
  };

  function getCurrentTime() {
    // Check if the player ref and current instance are available
    return playerRef.current ? playerRef.current.getCurrentTime() : 0;
  }

  const getVideoAndABTest = async () => {
    try {
      let videoIdsPayload;

      if (chosenVideoId && previousABVideoIds) {
        videoIdsPayload = {
          ABId: previousABVideoIds.ABId,
          videoAId: previousABVideoIds.videoAId,
          videoAPreviewId: previousABVideoIds.videoAPreviewId,
          videoBId: previousABVideoIds.videoBId,
          videoBPreviewId: previousABVideoIds.videoBPreviewId,
          videoPreviewWinnerId: previousABVideoIds.videoPreviewWinnerId,
          videoWinnerId: chosenVideoId,
        };
      }

      const response = await fetchVideoAndABTest(videoId, videoIdsPayload);
      setCurrentVideo(response?.video);
      setVideoA(response?.AB?.videoA);
      setVideoB(response?.AB?.videoB);
      setTestId(response?.AB?.id);
      setLikeActive(response?.video?.userLiked);
      setVideoAPreviewId(response?.videoAPreviewId);
      setVideoBPreviewId(response?.videoBPreviewId);
      return response?.video;
    } catch (error) {
      console.log(error);
    }
  };

  const getCSRFToken = async () => {
    try {
      const response = await fetchCSRFToken();
      setCSRFToken(response?.csrf_token);
      return response?.csrf_token;
    } catch (error) {
      console.log(error);
      navigation("/not_found");
    }
  };

  const addViewToVideo = async (videoId, csrfToken) => {
    try {
      await watchVideo(videoId, csrfToken);
    } catch (error) {
      console.log(error);
      navigation("/not_found");
    }
  };

  const handlePreviewclick = () => {
    setIsMuted(false);
    setPlaying(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let csrfToken = await getCSRFToken();
      let video = await getVideoAndABTest();
      await addViewToVideo(video?.id, csrfToken);
      setVideoUrl(config.remoteApiUrl + "/videos/stream/" + video?.id);

      if (isInitialPage) {
        setLight(getPreviewUrl(video));
        setIsInitialPage(false);
      } else {
        setLight(false);
        setIsMuted(false);
        setPlaying(true);
      }

      setIsLoading(false);
    };
    fetchData();
  }, [videoId]);

  const PlayButton = () => {
    return (
      <button
        style={{
          background: "red",
          border: "none",
          borderRadius: "8px",
          width: "64px",
          height: "48px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          outline: "none",
          transition: "all 0.2s ease-in-out",
        }}
        onMouseOver={({ currentTarget }) => {
          currentTarget.style.transform = "scale(1.1)";
          currentTarget.style.boxShadow = "0 2px 4px rgba(0,0,0,0.5)";
        }}
        onMouseOut={({ currentTarget }) => {
          currentTarget.style.transform = "scale(1)";
          currentTarget.style.boxShadow = "none";
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="white"
          width="24"
          height="24"
        >
          <path d="M11.596 8.697 6 11.196V6.196l5.596 2.501zM6 4.5a.5.5 0 0 1 .804-.396l6 4.5a.5.5 0 0 1 0 .792l-6 4.5A.5.5 0 0 1 6 13.5v-9z" />
        </svg>
      </button>
    );
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="video-platform">
      <div className="main-content">
        <div className="left-section">
          <div className="video-player">
            <ReactPlayer
              playing={playing}
              playsinline={true}
              muted={isMuted}
              ref={playerRef}
              light={light}
              className="react-player"
              url={videoUrl}
              width="100%"
              height="100%"
              controls
              onEnded={handleVideoEnd}
              onPause={handleStop}
              onSeek={(e) => {
                handleSeek(e);
              }}
              playIcon={<PlayButton />}
              onProgress={handleProgress}
              onClickPreview={handlePreviewclick}
            />
          </div>
        </div>
        <div className="right-section">
          <div className="video-list">
            <div
              className="video-item"
              key={videoA.id}
              onClick={() => handlePickA()}
            >
              <div className="thumbnail-container">
                <img
                  src={
                    config.remoteApiUrl + "/videos/previews/" + videoAPreviewId
                  }
                  alt={videoA.title}
                  className="thumbnail"
                />
                <div className="duration">{videoA.duration}</div>
              </div>
              <div className="video-info">
                <h3>{videoA.title}</h3>
              </div>
            </div>
            <div
              className="video-item"
              key={videoB.id}
              onClick={() => handlePickB()}
            >
              <div className="thumbnail-container">
                <img
                  src={
                    config.remoteApiUrl + "/videos/previews/" + videoBPreviewId
                  }
                  alt={videoB.title}
                  className="thumbnail"
                />
                <div className="duration">{videoB.duration}</div>
              </div>
              <div className="video-info">
                <h3>{videoB.title}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-details">
        <h1 className="video-title">{currentVideo.title}</h1>
        <div className="channel-video-container">
          <div className="views">
            {parseInt(currentVideo.viewCount).toLocaleString("ru-RU")}{" "}
            Просмотров
          </div>
          <div className="like-dislike-container">
            <div className="youtube-buttons">
              <button
                className={`like-button ${likeActive ? "liked" : ""}`}
                onClick={toggleLike}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
                <span>{formatNumber(parseInt(currentVideo.likeCount))}</span>
              </button>
              <button
                className={`dislike-button ${dislikeActive ? "disliked" : ""}`}
                onClick={toggleDislike}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlatform;
